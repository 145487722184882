import React, { useEffect, useState } from "react";

import NavBar from "../../components/Constant/NavBar";
import ForgotPassword from "../../components/Signin/ForgotPassword";
import SigninForm from "../../components/Signin/SigninForm";
import SignupForm from "../../components/Signin/SignupForm";
import styles from "./MainPage.module.css";

const MainPage = (props) => {
  const [page, setPage] = useState("signin");

  useEffect(() => {
    if (props.match.url === "/auth/signup") {
      setPage("signup");
    }
  }, [props.match.url]);

  const PageLoad = () => {
    switch (page) {
      case "signin":
        return <SigninForm setPage={setPage} {...props} />;
      case "signup":
        return <SignupForm setPage={setPage} {...props} />;
      case "forgot":
        return <ForgotPassword setPage={setPage} {...props} />;
      case "navbar":
        return <NavBar setPage={setPage} {...props} />;
      default:
        return <SigninForm setPage={setPage} {...props} />;
    }
  };

  return (
    <div className={styles.main + " container-fluid d-flex mainPage"}>
      <div className={styles.right_side + ""}>
        <div className="card card-body">{PageLoad()}</div>
      </div>
    </div>
  );
};

export default MainPage;
